@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.Dashboard2 {
  h1 {
    font-size: 21px;
    line-height: 25px;
    color: #000000;
    font-weight: bold;
  }
  h2 {
    font-size: 13px;
    line-height: 15px;
    color: #898989;
    font-weight: normal;
    font-style: italic;
  }
  .logo {
    width: auto;
    height: 25px;
  }
  .trend-for, .county {
    max-width: 220px;
    margin-left: 10px;
  }
  .mtime {
    color: #555;
    font-size: 13px;
    font-style: italic;
  }
  .chart-wrapper {
    font-size: 12px;
  }
}

.hdc-tooltip {
  background: #fff;
  font-size: 13px;
  padding: 10px;
  box-shadow: 0 0 3px 2px #0001;
  div {
    display: flex;
    align-items: center;
  }
  label {
    color: #898989;
    margin: 0 5px 0 0;
    &:after {
      content: ':';
    }
  }
  .header {
    text-transform: uppercase;
    color: #898989;
    font-size: 11px;
    font-weight: 600;
    margin-bottom: 5px;
  }
}